import { api } from "./api"

export const GET_ALL_COMPANIES = async () => {
    const { data } = await api.get(`/companies/all`)
    return data;
}

export const GET_REPORTS = async () => {
    const { data } = await api.get(`/admin/reports`)
    return data;
}

export const GET_LOGS = async () => {
    const { data } = await api.get(`/admin/logs`)
    return data;
}
