import { ActionIcon, Button, Grid, Group, Paper, Stack, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { SAVE_PLAN, GET_ALL_PLANS, DELETE_PLAN } from "../services/plans";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { useApp } from "../contexts/app.context";
import { showNotification } from "@mantine/notifications";
import { FaPlus } from "react-icons/fa";

export default function PlansPage() {
    const [plans, setPlans] = useState<any[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();

    const loadPlans = () => {
        GET_ALL_PLANS()
            .then(res => {
                setPlans(res);
            })
            .catch(err => {
                showNotification({ message: err.message, color: "red" })
            })
    }

    const handleSave = (res) => {
        let params = { ...selectedPlan };

        setLoadingSave(true)
        SAVE_PLAN(params)
            .then((res) => {
                loadPlans();
                setSelectedPlan(res)
                setLoadingSave(false);
            })
            .catch(err => {
                showNotification({ message: err.message, color: "red" })
                setLoadingSave(false);
            })
    }

    useEffect(loadPlans, []);

    return <div style={{ position: 'relative' }}>
        <Grid>
            <Grid.Col md={12}>
                <Stack>
                    <Paper shadow="xs" p="md">
                        <Group>
                            <InputField
                                name="pl"
                                style={{ flex: 1 }}
                                onChange={({ pl }) => {
                                    pl === "new"
                                        ? setSelectedPlan({})
                                        : setSelectedPlan(plans.find(p => p._id === pl))
                                }}
                                value={selectedPlan ? selectedPlan?._id || "new" : null}
                                fieldType={InputFieldTypes.SELECT}
                                clearable={false}
                                searchable={false}
                                options={[
                                    ...plans
                                    .sort((a,b) => a.title > b.title ? 1 : -1)
                                    .map(p => ({ label: `${p.title}${p.status === 0 ? " [INATIVO]" : ""}`, value: p._id })),
                                    { value: "new", label: "Adicionar Plano" }
                                ]}
                            />
                            {selectedPlan?._id && <Button color="gray" variant="outline"
                                onClick={() => {
                                    confirmDialog(
                                        { text: "Realmente alterar esse plano?" },
                                        ({ confirmed }) => {
                                            
                                            if (confirmed) SAVE_PLAN({ _id: selectedPlan?._id, status: selectedPlan?.status === 1 ? 0 : 1 })
                                                .then(() => {
                                                    loadPlans();
                                                    setSelectedPlan(null);
                                                })
                                                .catch(err => {
                                                    showNotification({ message: err.message })
                                                })
                                        }
                                    )
                                }}
                            >{selectedPlan?.status === 1 ? 'Inativar Plano' : 'Reativar Plano'}</Button>}
                        </Group>
                    </Paper>

                    {selectedPlan && <>
                        <Paper shadow="xs" p="md">
                            <Stack>
                                <Group grow>
                                    <InputField
                                        name="recurrence_period"
                                        onChange={({ recurrence_period }) => setSelectedPlan(sp => ({ ...sp, recurrence_period }))}
                                        value={selectedPlan?.recurrence_period ?? 1}
                                        title="Período da recorrência"
                                        style={{ flex: 1 }}
                                    />
                                    <InputField
                                        name="recurrence_type"
                                        title="Recorrência"
                                        value={selectedPlan?.recurrence_type ?? "month"}
                                        fieldType={InputFieldTypes.SELECT}
                                        placeholder='Period'
                                        searchable={false}
                                        clearable={false}
                                        style={{ flex: 1 }}
                                        onChange={({ recurrence_type }) => { setSelectedPlan(sp => ({ ...sp, recurrence_type })) }}
                                        options={[
                                            { value: "month", label: "Mensal" },
                                            // { value: "week", label: "Semanal" },
                                            { value: "year", label: "Anual" },
                                        ]}
                                    />
                                </Group>

                                <Group grow>
                                    <InputField
                                        name="price"
                                        mask={InputFieldMasks.MONEY}
                                        onChange={({ price }) => setSelectedPlan(sp => ({ ...sp, price }))}
                                        value={selectedPlan?.price}
                                        title="Valor Fixo"
                                        style={{ flex: 1 }}
                                    />
                                    <InputField
                                        name="max_installments"
                                        type="number"
                                        onChange={({ max_installments }) => setSelectedPlan(sp => ({ ...sp, max_installments }))}
                                        value={selectedPlan?.max_installments}
                                        title="Máximo de Parcelas"
                                        style={{ flex: 1 }}
                                    />
                                </Group>

                                <Group>
                                    <InputField
                                        name="max_customers"
                                        type="number"
                                        onChange={({ max_customers }) => setSelectedPlan(sp => ({ ...sp, max_customers }))}
                                        value={selectedPlan?.max_customers}
                                        title="Clientes Incluídos"
                                        style={{ flex: 1 }}
                                    />
                                    <InputField
                                        name="add_on_customer"
                                        mask={InputFieldMasks.MONEY}
                                        onChange={({ add_on_customer }) => setSelectedPlan(sp => ({ ...sp, add_on_customer }))}
                                        value={selectedPlan?.add_on_customer}
                                        title="Valor Por Cliente"
                                        style={{ flex: 1 }}
                                    />
                                </Group>

                                <Group>
                                    <InputField
                                        name="max_storage"
                                        type="number"
                                        onChange={({ max_storage }) => setSelectedPlan(sp => ({ ...sp, max_storage }))}
                                        value={selectedPlan?.max_storage}
                                        title="Max. Armazenamento (MB)"
                                        style={{ flex: 1 }}
                                    />
                                    <InputField
                                        name="add_on_storage"
                                        mask={InputFieldMasks.MONEY}
                                        onChange={({ add_on_storage }) => setSelectedPlan(sp => ({ ...sp, add_on_storage }))}
                                        value={selectedPlan?.add_on_storage}
                                        title="Valor Adicional por MB"
                                        style={{ flex: 1 }}
                                    />
                                </Group>

                                <Group>
                                    <InputField
                                        name="max_users"
                                        type="number"
                                        onChange={({ max_users }) => setSelectedPlan(sp => ({ ...sp, max_users }))}
                                        value={selectedPlan?.max_users}
                                        title="Max. Usuários"
                                        style={{ flex: 1 }}
                                    />
                                </Group>

                                <Group>
                                    <InputField
                                        name="content_publish"
                                        fieldType={InputFieldTypes.SWITCH}
                                        onChange={({ content_publish }) => setSelectedPlan(sp => ({ ...sp, content_publish }))}
                                        value={selectedPlan?.content_publish}
                                        title="Permite agendar publicações"
                                        style={{ flex: 1 }}
                                    />
                                </Group>
                            </Stack>
                        </Paper>

                        <Paper shadow="xs" p="md">
                            <Stack>
                                <InputField
                                    name="title"
                                    onChange={({ title }) => setSelectedPlan(sp => ({ ...sp, title }))}
                                    value={selectedPlan?.title}
                                    title="Nome do Plano"
                                />
                                <InputField
                                    name="description"
                                    fieldType={InputFieldTypes.TEXTAREA}
                                    onChange={({ description }) => setSelectedPlan(sp => ({ ...sp, description }))}
                                    value={selectedPlan?.description}
                                    title="Descrição do Plano"
                                />
                            </Stack>
                        </Paper>

                        <Group>
                            <Text style={{ flex: 1 }}>Módulos</Text>
                            <ActionIcon
                                onClick={() => {
                                    setSelectedPlan(p => ({
                                        ...p,
                                        resources: [...(p.resources ?? []), {}]
                                    }))
                                }}
                            ><FaPlus /></ActionIcon>
                        </Group>
                        {/* {(selectedPlan?.resources ?? []).map((res, ri) => <Paper shadow="xs" p="md">
                            <Group>
                                <div style={{ flex: 1 }}>
                                    <InputField
                                        name="key"
                                        onChange={({ key }) => setSelectedPlan(sp => ({ ...sp, resources: selectedPlan?.resources.map((r, rj) => ri === rj ? { ...r, key } : r) }))}
                                        value={res?.key}
                                        title="Recurso"
                                        fieldType={InputFieldTypes.SELECT}
                                        options={[
                                            { value: "storage", label: "Arquivos (GB)" },
                                            { value: "content_publish", label: "Publicação Automática (0 ou 1)" },
                                        ]}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <InputField
                                        name="quantity"
                                        onChange={({ quantity }) => setSelectedPlan(sp => ({ ...sp, resources: selectedPlan?.resources.map((r, rj) => ri === rj ? { ...r, quantity } : r) }))}
                                        value={res?.quantity}
                                        title="Quantidade"
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <InputField
                                        name="value"
                                        onChange={({ value }) => setSelectedPlan(sp => ({ ...sp, resources: selectedPlan?.resources.map((r, rj) => ri === rj ? { ...r, value: value ? parseInt(value) : value } : r) }))}
                                        value={res?.value}
                                        title="Valor Unitário"
                                    />
                                </div>
                            </Group>
                        </Paper>)} */}
                        <Button loading={loadingSave} onClick={handleSave}>Salvar</Button>
                    </>}

                </Stack>
            </Grid.Col>
        </Grid>
    </div>
}
