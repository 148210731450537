import { ActionIcon, Badge, Group, Paper, Text, Tooltip } from "@mantine/core";
import moment from 'moment';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar';
import { useNavigate } from "react-router-dom";
import { groupBy, extenseWeekday, getMedia, getExtenseDatetime } from "../utility/util";
import { FaCheck } from "react-icons/fa6";
dayjs.locale('pt-br')

const localizer = dayjsLocalizer(dayjs)

function Event({ event }) {
    return <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', alignItems: 'center' }}>
        {(event.medias ?? []).map(({ Icon, color, key }) => {
            const published = (event.destinations ?? []).some(d => d.origin === key && (d?.publications ?? []).some(p => !p.error));
            return <Tooltip label={published ? "Publicado" : "Agendado"}>
                <ActionIcon mr="3px" size="sm" color={published ? "green" : color} variant={published ? "filled" : "outline"}><Icon /></ActionIcon>
            </Tooltip>
        })}
        <Text c="black" fw="bold" size="xs">{event.title}</Text>
    </div>
}

export const JobsCalendar = ({ jobs }) => {
    const [tasks, setTasks] = useState([]);
    const navigate = useNavigate();

    const { messages, formats, components, defaultDate } = useMemo(
        () => ({
            components: {
                // agenda: {
                //     event: Event,
                // },
                month: { event: Event },
                event: Event,
            },
            defaultDate: moment().toDate(),
            formats: {
                dayHeaderFormat: (date) => getExtenseDatetime(date, false),
                timeGutterFormat: (date) => moment(date).format("HH:mm"),
                dayFormat: (date) => moment(date).format("DD"),
                weekdayFormat: (date) => extenseWeekday(moment(date).weekday()),
                eventTimeRangeFormat: () => "",
            },
            messages: {
                date: 'Data',
                time: 'Hora',
                event: 'Job',
                allDay: 'Dia Todo',
                week: 'Semana',
                work_week: 'Dia Útil',
                day: 'Dia',
                month: 'Mês',
                previous: 'Anterior',
                next: 'Próximo',
                yesterday: 'Ontem',
                tomorrow: 'Amanhã',
                today: 'Hoje',
                agenda: 'Agenda',

                noEventsInRange: 'Nenhum job no período selecionado.',
                showMore: (total, remainingEvents, events) => `+${total} jobs`,
            },
        }),
        []
    );

    const eventPropGetter = useCallback(
        (event, start, end, isSelected) => ({
            style: {
                background: '#F4F4F4',
                border: '1px solid #CACACA',
                color: '#8a8a8a',
                padding: '2px',
                minHeight: 25,
            }
        }),
        []
    )

    useEffect(() => {
        setTasks(jobs.reduce((pv, job) => {
            const tasks: any[] = job.tasks.reduce((pv, t) => pv.concat(t.destinations ?? []), []).filter(t => t.publish_date)
            const grouped = groupBy(tasks, t => moment(t.publish_date).format("YYYY-MM-DD HH"));

            const items = grouped
                .map(g => {
                    const medias = groupBy(g[1], t => t.origin).filter(g => g).map(g => getMedia(g[0]));
                    const date = moment(`${g[0]}:00:00`).startOf("hour").toDate();
                    return {
                        jobId: job.id,
                        medias,
                        destinations: g[1],
                        title: `${(g[1].length === 1 && g[1][0].name) ? `${g[1][0].name} - ` : ""}${job.name} [${job?.customer?.name ?? ""}]`,
                        start: date,
                        end: date,
                    }
                })
            console.log(items);

            return pv.concat(items);
        }, []))
    }, [jobs]);

    return <Paper p="md" shadow="xs">
        <Calendar
            components={components}
            localizer={localizer}
            events={tasks}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventPropGetter}
            dateCellWrapper={{ style: { display: 'none' } }}
            formats={formats}
            messages={messages}
            defaultDate={defaultDate}
            defaultView={Views.MONTH}
            onSelectEvent={(item) => { navigate(`/jobs/${item.jobId}`) }}
            style={{ height: '85vh' }}
        />
    </Paper>
}