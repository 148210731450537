import { ActionIcon, Checkbox, Divider, Group, Menu, Paper, Text, Title } from '@mantine/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth.context';
import Table from '../components/table';
import { showNotification } from '@mantine/notifications';
import { DELETE_FILES, GET_ALL_FILES } from '../services/files';
import { formatFileSize } from '../utility/util';
import { FaEllipsisV, FaEye } from 'react-icons/fa';
import { useApp } from '../contexts/app.context';
import InputField, { InputFieldTypes } from '../components/input_field';
import { ActionsRender } from '../components/actions_render';

export default function OverviewPage() {
    const { user: currentUser, role } = useAuth();
    const { confirmDialog, jobs } = useApp();

    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filters, setFilters] = useState<any>({});
    const [loading, setLoading] = React.useState(false);

    const shownItems = items.filter(it => (
        (!(filters?.period ?? [])[0] || moment(filters.period[0]).format("YYYY-MM-DD") <= moment(it.createdAt).format("YYYY-MM-DD")) &&
        (!(filters?.period ?? [])[1] || moment(filters.period[1]).format("YYYY-MM-DD") >= moment(it.createdAt).format("YYYY-MM-DD"))
    ))

    const loadFiles = () => {
        setLoading(true);

        GET_ALL_FILES()
            .then(res => {
                setItems(res);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                showNotification({ message: err.message, color: 'red' });
            })
    }

    useEffect(loadFiles, [])
    useEffect(() => { setSelectedItems([]); }, [filters])

    return <>
        <Paper p="md" shadow='xs'>
            <Group style={{ justifyContent: 'space-between' }} align="flex-end">
                <Group style={{ flex: 1 }} align="flex-end">
                    <ActionsRender
                        disabled={selectedItems.length === 0}
                        actions={[
                            {
                                title: "Deletar Arquivos",
                                onClick: () => {
                                    const previous = items;
                                    const previousSelected = selectedItems;
                                    setItems(its => its.filter(i => !selectedItems.includes(i._id)));
                                    setSelectedItems([]);
                                    DELETE_FILES(selectedItems)
                                        .then(() => { })
                                        .catch((err: any) => {
                                            setItems(previous);
                                            setSelectedItems(previousSelected);
                                            showNotification({ message: err.message })
                                        })
                                }
                            }
                        ]}
                    />
                    <div style={{ minWidth: 200 }}>
                        <InputField
                            title="Período"
                            fieldType={InputFieldTypes.DATERANGE}
                            value={filters.period}
                            name="period"
                            onChange={({ period }) => setFilters(f => ({ ...f, period }))}
                        />
                    </div>
                </Group>
                <Title order={4} color="lime" align='right'>Total: {formatFileSize(shownItems.reduce((pv, r) => (pv + (isNaN(r.size) ? 0 : r.size)), 0))}/{formatFileSize(items.reduce((pv, r) => (pv + (isNaN(r.size) ? 0 : r.size)), 0))}</Title>
            </Group>
            <Table
                loading={loading}
                data={shownItems}
                columns={[
                    {
                        title: () => <Checkbox
                            checked={selectedItems.length > 0 && shownItems.every(it => selectedItems.includes(it._id))}
                            onChange={() => setSelectedItems(si => (
                                shownItems.every(it => selectedItems.includes(it._id))
                                    ? []
                                    : shownItems.map(i => i._id)
                            ))}
                        />,
                        key: "select",
                        render: item => <Checkbox
                            checked={selectedItems.includes(item._id)}
                            onChange={() => setSelectedItems(si => (
                                selectedItems.includes(item._id)
                                    ? si.filter(it => it !== item._id)
                                    : [...si, item._id]
                            ))}
                        />
                    },
                    { title: "Data", key: "createdAt", render: item => moment(item.createdAt).format("DD/MM/YYYY") },
                    {
                        title: "Job", key: "job", render: item => {
                            const findItem = t => t.items.some(it => it.value === (item.url ?? item.generatedUrl));
                            const job = jobs.find(j => (j.tasks ?? []).some(findItem));
                            return job
                                ? `${job.name} - ${(job.tasks ?? []).find(findItem).name}`
                                : " "
                        }
                    },
                    { title: "Tamanho", key: "size", render: item => formatFileSize(item.size) },
                    { title: "Arquivo", key: "original_name", render: item => item.original_name ?? item.filename },
                ]}
                actions={[
                    {
                        title: "Visualizar",
                        onClick: ({ item }) => window.open((item.url ?? item.generatedUrl), "_blank")
                    },
                    {
                        title: "Deletar", color: "red", onClick: ({ item }) => {
                            confirmDialog(
                                { text: "Realmente deseja deletar esse arquivo?" },
                                (({ confirmed }) => {
                                    if (confirmed) {
                                        const previous = items;
                                        setItems(its => its.filter(i => i._id !== item._id))
                                        DELETE_FILES([item._id])
                                            .then(() => { })
                                            .catch((err: any) => {
                                                setItems(previous);
                                                showNotification({ message: err.message })
                                            })
                                    }
                                })
                            )
                        }
                    },
                ]}
            />
        </Paper>
    </>
}
