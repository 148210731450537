import {
    AppShell,
    Button,
    Group,
    LoadingOverlay,
    Paper,
    Text,
    UnstyledButton
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { default as React, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import PublicJobPage from './public_job';
import PreviewJob from './job_preview';
import { GET_PUBLIC_LINK_DATA } from '../services/auth';
import SimpleHeader from '../components/simple_header';
import { useQuery } from '../utility/util';

export default function PublicPage(){
    const { key } = useParams()
    const navigate = useNavigate()
    const query = useQuery()
    const { startPlan } = useApp()
    const { role, logout } = useAuth();
    const { configs, setConfigs } = useApp();

    const isMobile = useMediaQuery('(max-width: 1200px)');

    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        GET_PUBLIC_LINK_DATA(key)
        .then(res => {
            setLoading(false)
            setData(res)
            if(res?.company){
                setConfigs({
                    name: res.company?.name,
                    image: res.company?.image,
                    icon: res.company?.icon,
                    primary: res.company?.appearance?.primary ?? "#0257cc",
                    navbar: res.company?.appearance?.navbar ?? "lime",
                })
            }
        })
        .catch(err => {
            setLoading(false)
        })
    }, [key])

    // if(data?.origin === "job" && !query.get("feed")) return <PreviewJob jobId={data?.props?.jobId} />
    return <AppShell
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        fixed
        padding={0}
    >
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: isMobile ? "20px 20px" : "20px 100px",
                borderBottom: '1px solid #EFEFEF44',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
            }}
        >
            <Group style={{flex: 1}}>
                <div style={isMobile ? { flex: 1, textAlign: 'center' } : {}}>
                    <img alt={configs?.name ?? "AprovaAi"} src={configs?.image ?? `/logo-white.png`} style={{height: 35}}/>
                </div>

                <Button
                    onClick={() => { role ? navigate("/jobs") : navigate("/?from=jobs")}}
                    color="lime"
                    ml="lg"
                    style={{color: 'white'}}
                >Início</Button>
            </Group>
        </div>
        <div style={{
            // backgroundImage: configs?.navbar === "blue" ? 'url(/header-bg.jpg)' : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: configs?.brand,
            height: 300
        }}></div>
        <div style={{marginTop: -200, padding: isMobile ? "20px 20px" : "0 100px",}}>
            { data?.origin === "job" && <PublicJobPage
                jobId={data?.props?.jobId}
                approver={data?.props?.approver}
            />}
            { !data?.origin && !loading && <>
                <SimpleHeader title={"Indisponível"} />
                <Paper style={{minHeight: 220, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} p="md" shadow='xs'>
                    <Text size="xl" align="center">Esse link não está mais disponível</Text>

                </Paper>
                {role?.profile?.title === "Administrador" && <>
                    <Text mt="md">Você não possui nenhuma assinatura vigente, escolha um plano para continuar</Text>
                    <Button mt="md" onClick={() => { startPlan() }}>Iniciar Assinatura</Button>
                </>}
                <UnstyledButton
                    onClick={() => {
                        logout()
                        navigate("/")
                    }}
                    mt="md"
                    style={{width: '100%'}}
                >
                    <Text c="blue" td="underline">Sair da conta</Text>
                </UnstyledButton>
            </>}
        </div>
        <div style={{height: 80}}></div>

        <LoadingOverlay visible={loading} />
  </AppShell>    
}