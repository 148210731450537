import {
  Box,
  Button,
  Dialog,
  Group,
  Image,
  MantineProvider,
  Modal,
  Progress,
  Text,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { createContext, useContext, useMemo } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Socket, connect } from "socket.io-client";
import usePersistState from "../hooks/usePersistState";
import { GET_ALL_USERS } from "../services/users";
import ChoosePlan from "../components/choose_plan";
import { useAuth } from "./auth.context";
import PublicPage from "../pages/public";
import { Helmet } from "react-helmet";
import { bestContrast, lightenColor } from "../utility/util";
import useFilePicker from "../components/file_picker";
import { GET_ALL_WHATSAPPS } from "../services/phones";
import { GET_ALL_JOBS } from "../services/jobs";
import { GET_ALL_CUSTOMERS } from "../services/customers";

interface ConfirmDialogProps {
  (
    props: { text: string; buttonText?: string },
    callback: (props: { confirmed: boolean }) => void
  ): void;
}
interface ConfigsProps {
  name?: string;
  image?: string;
  icon?: string;
  primary?: string;
  navbar?: string;
  brand?: string;
  contrast?: string;
}
interface AppContextProps {
  openTheme: (th: any) => void;
  confirmDialog: ConfirmDialogProps;
  setColorScheme: (colorScheme: "dark" | "light") => void;
  socket: Socket;
  pickFiles: ({ onSelect }) => void;
  registration?: ServiceWorkerRegistration;
  muted: boolean;
  setMuted: (isMuted: boolean) => void;
  agents: any[];
  openedSidebar?: boolean;
  toggleOpenedSidebar?: () => void;
  startPlan: (start?: boolean) => void;
  expandImage?: (url: string, options?: { drive?: boolean }) => void;
  expandText?: (text: string) => void;
  configs: ConfigsProps;
  setConfigs: (vl: ConfigsProps) => any;
  jobs: any[];
  loadJobs: () => void;
  setJobs: any;
  customers: any[];
  loadCustomers: () => void;
  setCustomers: any;
  whatsapps: any[],
}

const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const lsColorScheme = localStorage.getItem("@aprovaai/colorScheme") as
    | "light"
    | "dark";

  const [openedConfirm, setOpenedConfirm] = React.useState(null);
  const [openThemeObj, setOpenTheme] = React.useState(null);
  const [startPlan, setStartPlan] = React.useState(false);
  const [loadingJobs, setLoadingJobs] = React.useState(false);
  const [loadingCustomers, setLoadingCustomers] = React.useState(false);
  const [jobs, setJobs] = React.useState<any[]>([]);
  const [customers, setCustomers] = React.useState<any[]>([]);
  const [muted, setMuted] = React.useState(true);
  const [importingFiles, setImportingFiles] = React.useState([]);
  const [configs, setConfigs] = React.useState<ConfigsProps>(null);
  const [agents, setAgents] = React.useState<any[]>([]);
  const [whatsapps, setWhatsapps] = React.useState<any[]>([]);
  const [openImage, setOpenImage] = React.useState<{ src: string; drive?: boolean }>(null);
  const [openText, setOpenText] = React.useState<string>("");
  const [colorScheme, setColorScheme] = React.useState<"dark" | "light">(
    lsColorScheme || "light"
  );
  const [currentRole, setCurrentRole] = React.useState(null);
  const [registration, setRegistration] =
    React.useState<ServiceWorkerRegistration>(null);

  const [socket, setSocket] = React.useState<Socket>(null);

  const { role, userData, user: currentUser, companyHost } = useAuth();
  const { pickFiles, Component: FilePickerComponent } = useFilePicker();

  const [openedSidebar, setOpenedSidebar] = usePersistState<boolean>(
    "@aprovaai/opened-sidebar",
    false
  );

  // const loadAgents = (setLoading = true) => {
  //   if (currentUser && role) {
  //     GET_ALL_USERS()
  //       .then((dt) => {
  //         setAgents(dt.sort((a, b) => (a.name > b.name ? 1 : -1)));
  //       })
  //       .catch((err) => {
  //         showNotification({
  //           title: "Ops",
  //           message: err.message,
  //           color: "red",
  //         });
  //       });
  //   }
  // };
  const loadJobs = () => {
    if (currentUser) {
      setLoadingCustomers(true)
      GET_ALL_JOBS()
        .then((res: any[]) => {
          setJobs(res);
          setLoadingCustomers(false)
        })
        .catch((err) => {
          showNotification({
            title: "Ops",
            message: err.message,
            color: "red",
          });
          setLoadingCustomers(false)
        });
    }
  };

  const loadCustomers = () => {
    if (currentUser) {
      setLoadingCustomers(true)
      GET_ALL_CUSTOMERS()
        .then(res => {
          setCustomers(res);
          setLoadingCustomers(false)
        })
        .catch(err => {
          setLoadingCustomers(false)
          showNotification({ title: "Ops", message: err.message, color: 'red' })
        })
    }
  }

  const confirmDialog: ConfirmDialogProps = (
    { text, buttonText = "Confirmar" },
    callback
  ) => {
    setOpenedConfirm({ text, buttonText, callback });
  };

  const openTheme = (th) => {
    setOpenTheme(th);
  };

  const toggleOpenedSidebar = () => {
    setOpenedSidebar(!openedSidebar);
  };

  React.useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          setRegistration(registration);
        })
        .catch((error) => {
          console.error("Service worker registration failed:", error);
        });
    }
  }, []);

  React.useEffect(() => {
    if (socket) {
      const func = ({ userId, status }) => {
        setAgents((items) =>
          items.map((ag) => (ag._id === userId ? { ...ag, status } : ag))
        );
      };
      socket.on(`user-status-update`, func);
      return () => {
        socket.removeAllListeners(`user-status-update`);
      };
    }
  }, [socket]);

  React.useEffect(() => {
    if (openTheme) setCurrentRole(role);
    window.onpopstate = (e) => {
      if (openThemeObj) {
        e.preventDefault();
        setOpenTheme(null);
        return false;
      }
    };
  }, [openThemeObj]);

  React.useEffect(() => {
    setConfigs(c => ({
      name: companyHost?.name ?? userData?.company?.name,
      image: companyHost?.image ?? userData?.company?.image,
      icon: companyHost?.icon ?? userData?.company?.icon,
      primary: companyHost?.appearance?.primary ?? userData?.company?.appearance?.primary ?? "#0257cc",
      navbar: companyHost?.appearance?.navbar ?? userData?.company?.appearance?.navbar ?? "#24508C",
    }))
  }, [companyHost, userData]);

  React.useEffect(() => {
    localStorage.setItem("@aprovaai/colorScheme", colorScheme);
  }, [colorScheme]);

  React.useEffect(() => {
    const localUser = localStorage.getItem("@aprovaai/user");
    const user = localUser ? JSON.parse(localUser) : null;
    const s = connect(process.env.REACT_APP_SERVER_URL, {
      extraHeaders: {
        Authorization: user?.token ? `Bearer ${user.token}` : undefined,
      },
    });
    setSocket(s);
  }, [currentUser]);

  React.useEffect(loadJobs, [currentUser, role]);
  React.useEffect(loadCustomers, [currentUser, role]);

  React.useEffect(() => {
    if (role) {
      GET_ALL_WHATSAPPS()
        .then(res => {
          setWhatsapps(res)
        })
        .catch(err => { })
    }
  }, [role, currentUser]);

  const brand = {
    "blue": "#0257cc",
    "white": "#F0F0F0",
    "black": "#0a0a0a",
  }[configs?.navbar ?? "blue"] ?? configs?.navbar;
  const contrast = bestContrast(brand);

  const value = useMemo<AppContextProps>(() => {
    return {
      confirmDialog,
      setColorScheme,
      openTheme,
      pickFiles,
      socket,
      jobs,
      loadJobs,
      customers,
      loadCustomers,
      setJobs,
      setCustomers,
      registration,
      agents,
      muted,
      whatsapps,
      setMuted,
      startPlan: (start = true) => setStartPlan(start),
      configs: {
        ...configs,
        brand,
        contrast,
      },
      setConfigs: (c) => {
        setConfigs(v => ({ ...v, ...c }));
      },
      openedSidebar,
      toggleOpenedSidebar,
      expandImage: (src, { drive = false } = {}) => setOpenImage({ src, drive }),
      expandText: (text) => setOpenText(text),
    };
  }, [
    confirmDialog,
    setColorScheme,
    configs,
    companyHost,
    socket,
    registration,
    agents,
    muted,
    whatsapps,
    openedSidebar,
    jobs,
    toggleOpenedSidebar,
  ]);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      withCSSVariables
      theme={{
        colorScheme: "light",
        loader: "dots",
        fontFamily: "Montserrat, sans-serif",
        datesLocale: "pt-br",
        colors: {
          // dark: [
          //   '#0a0a0a',
          //   lightenColor(configs?.primary ?? "#0257cc", 90), 
          //   '#8c8fa3',
          //   '#666980',
          //   lightenColor(brand, 40),
          //   '#6a6a6a',
          //   lightenColor(contrast, 94),
          //   '#FFFFFF',
          //   '#F0F0F0',
          //   '#01010a',
          // ],
          lime: [
            lightenColor(configs?.primary ?? "#0257cc", 85),
            lightenColor(configs?.primary ?? "#0257cc", 90),
            `${configs?.primary ?? "#0257cc"}`,
            configs?.primary ?? "#0257cc",
            configs?.primary ?? "#0257cc",
            configs?.primary ?? "#0257cc",
            configs?.primary ?? "#0257cc",
            `${configs?.primary ?? "#0257cc"}99`,
            configs?.primary ?? "#0257cc",
            configs?.primary ?? "#0257cc",
          ],
        },
        headings: {
          fontWeight: "bold",
        },
        primaryColor: "lime",
      }}
    >
      <Helmet>
        <title>{configs?.name ?? "Aprova Aí"}</title>
        <link rel="icon" href={configs?.icon ?? "/favicon.ico"} />
      </Helmet>
      <AppContext.Provider value={value}>
        <>
          <>
            <Dialog
              opened={Boolean(openedConfirm)}
              withCloseButton
              onClose={() => setOpenedConfirm(null)}
              size="lg"
              radius="md"
              zIndex={9999}
            >
              <Text size="sm" style={{ marginBottom: 10 }} weight={500}>
                {openedConfirm?.text}
              </Text>

              <Group align="flex-end">
                <Button
                  onClick={() => {
                    openedConfirm?.callback &&
                      openedConfirm?.callback({ confirmed: true });
                    setOpenedConfirm(null);
                  }}
                >
                  {openedConfirm?.buttonText}
                </Button>
              </Group>
            </Dialog>
          </>
        </>
        {configs && (
          (!userData?.company?.pendent || userData?.user?.isAdmin || window.location.pathname === "/checkout") ? children : <PublicPage />
        )}
        <Dialog opened={importingFiles.length > 0}>
          {importingFiles.map((imp) => (
            <div>
              <div style={{ textAlign: "right" }}>
                <Text color="lime" size="sm">
                  {imp.done}/{imp.total}
                </Text>
              </div>
              <Progress color="lime" value={imp.percent} />
              <Text size="xs" style={{ textAlign: "right" }}>
                {{ themes: "Importação de pautas" }[imp.type]} -{" "}
                {imp.origin.toUpperCase()}
              </Text>
            </div>
          ))}
        </Dialog>
        <Modal
          opened={!!openImage}
          onClose={() => setOpenImage(null)}
          fullScreen
          size="xl"
        >{openImage && (
          openImage?.drive
            ? <iframe
              frameBorder="0"
              width="100%"
              // height="600px"
              style={{ height: '90vh' }}
              src={openImage?.src}>
            </iframe>
            : <TransformWrapper
              limitToBounds
            >
              <TransformComponent>
                <Image
                  // onClick={() => zoomIn()}
                  src={openImage?.src} height={"90vh"} width="100%" fit="contain"
                />
              </TransformComponent>
            </TransformWrapper>
        )}</Modal>
        {FilePickerComponent}
        <Modal
          opened={!!openText}
          onClose={() => setOpenText(null)}
          size="md"
        ><Box style={{ border: 10, background: "#F4F4F4" }} p="lg">
            {(openText ?? "").split("\n").map((d) => <div style={{ minHeight: 14 }}>{d}</div>)}
          </Box>
        </Modal>

        <Modal
          opened={startPlan}
          onClose={() => setStartPlan(false)}
          size="90vw"
          withCloseButton={false}
        >
          <ChoosePlan onClose={() => setStartPlan(false)} />
        </Modal>
      </AppContext.Provider>
    </MantineProvider>
  );
};

export const useApp = () => useContext(AppContext);
