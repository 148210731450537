import { ActionIcon, Box, Button, Group, Loader, Modal, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import CsvReader from '../../components/csv_reader';
import InputField, { InputFieldTypes } from '../../components/input_field';
import Table from '../../components/table';
import { useApp } from '../../contexts/app.context';
import { SAVE_CUSTOMER } from '../../services/customers';
import { GET_ALL_WHATSAPP_GROUPS } from '../../services/phones';
import { GET_ALL_TEAMS } from '../../services/teams';

export const CustomerModal = (props) => {
    const { data: dataParent = null, onClose, onSave, opened = false } = props;
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [loadingGroups, setLoadingGroups] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>(null);
    const [teams, setTeams] = React.useState<any[]>([]);
    const [whatsappGroups, setWhatsappGroups] = React.useState<any[]>([]);
    const [importingItems, setImportingItems] = React.useState<any[]>([]);

    const { whatsapps } = useApp();

    const handleChange = dt => setData(d => ({ ...d, ...dt }));
    const handleChangeProps = dt => setData(d => ({ ...d, props: { ...d?.props, ...dt } }));

    const handleSaveItem = (dt) => {
        return new Promise((resolve, reject) => {
            SAVE_CUSTOMER(dt)
                .then((res) => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    const handleSave = async () => {
        const params = { ...data };
        delete params.typeInsert;

        try {
            if (data?.typeInsert === 'multiple') {
                setLoadingSave(true)
                await Promise.all(importingItems.map(handleSaveItem))
                setLoadingSave(false);
                onSave && onSave();
            } else {
                setLoadingSave(true)
                const res = await handleSaveItem({ ...params, id: dataParent?._id })
                setLoadingSave(false);
                onSave && onSave(res);
            }
        } catch (err) {
            setLoadingSave(false)
            showNotification({ title: "Ops", message: err.message, color: 'red' })
        }
    }

    useEffect(() => {
        if (opened) setData(d => ({ approvers: [{}], ...dataParent }))
    }, [opened, dataParent])

    useEffect(() => {
        GET_ALL_TEAMS()
            .then(res => {
                setTeams(res);
            })
            .catch(err => {
                showNotification({ message: err.message, color: "red" })
            })
    }, [])

    useEffect(() => {
        if (data?.whatsapp_notification) {
            setLoadingGroups(true)
            GET_ALL_WHATSAPP_GROUPS(data?.whatsapp_notification)
                .then(res => {
                    setLoadingGroups(false)
                    setWhatsappGroups(res);
                })
                .catch(err => {
                    setLoadingGroups(false)
                    showNotification({ message: err.message, color: "red" })
                })
        }
    }, [data?.whatsapp_notification])

    return <>
        <Modal
            opened={!!opened}
            onClose={onClose}
            size="xl"
        >
            <InputField
                mb="lg"
                name="typeInsert"
                title=""
                fieldType={InputFieldTypes.BOX}
                value={data?.typeInsert ?? "single"}
                onChange={handleChange}
                options={[
                    { label: "Individual", value: "single" },
                    { label: "Lista", value: "multiple" },
                ]}
            />
            {
                data?.typeInsert === "multiple"
                    ? <>
                        <Box>
                            <Link target='_blank' to="/customers_sample.csv">Baixar exemplo</Link>
                        </Box>

                        {importingItems.length > 0
                            ? <>
                                <Button mt="xl" onClick={() => setImportingItems([])} variant='light'>Remover Lista</Button>
                                <Table
                                    data={importingItems}
                                    columns={[
                                        { title: "", key: "act", render: (it, index) => <ActionIcon size="xs" variant='light' onClick={() => setImportingItems(it => it.filter((it2, j) => j !== index))}><FaTimesCircle /></ActionIcon> },
                                        { title: "Nome", key: "name", render: (it) => it.name },
                                        { title: "Aprovador", key: "ap_name", render: (it) => it.approvers[0]?.name },
                                        { title: "E-mail", key: "ap_email", render: (it) => it.approvers[0]?.email },
                                        { title: "Telefone", key: "ap_phone", render: (it) => it.approvers[0]?.phone },
                                    ]}
                                />
                            </>
                            : <CsvReader onData={({ items, columns }) => {
                                setImportingItems(items.map(it => ({
                                    name: it["Nome"] ?? it["Nome Contato"],
                                    active: true,
                                    approvers: [
                                        {
                                            name: it["Nome Contato"] || it["Nome"],
                                            email: it["E-mail Contato"] || it["E-mail principal"] || it["Email principal"] || it["E-mail Principal"] || it["E-mail"],
                                            phone: it["Whatsapp"] || it["Telefone Contato"] || it["Telefone"],
                                        }
                                    ]
                                })));
                            }} />}
                    </>
                    : <>

                        <InputField
                            mb="lg"
                            name="image"
                            title="Logo"
                            fieldType={InputFieldTypes.IMAGE}
                            value={data?.image}
                            onChange={handleChange}
                        />
                        <InputField
                            mt="lg"
                            mb="lg"
                            name="name"
                            title="Nome do cliente"
                            value={data?.name}
                            onChange={handleChange}
                        />
                        <InputField
                            mt="lg"
                            mb="lg"
                            name="email"
                            title="E-mail do cliente"
                            value={data?.email}
                            onChange={handleChange}
                        />
                        <InputField
                            mt="lg"
                            mb="lg"
                            name="teams"
                            title="Times"
                            value={((!data?.not_for_all && (data?.teams ?? []).length === 0) ? ["all"] : data?.teams)}
                            onChange={({ teams }) => {
                                const isAll = (!data?.not_for_all && (data?.teams ?? []).length === 0);
                                const onlyTeams = teams.filter(t => t !== "all");
                                let nd = {
                                    not_for_all: !teams.includes("all"),
                                    teams: (isAll && onlyTeams.length > 0)
                                        ? onlyTeams
                                        : (teams.includes("all")) ? [] : onlyTeams,
                                }
                                handleChange(nd);
                            }}
                            multiple
                            options={[
                                ...teams.map(t => ({ label: t.title, value: t._id })),
                                { label: "Todos", value: "all" }
                            ]}
                            fieldType={InputFieldTypes.SELECT}
                        />
                        <InputField
                            mb="lg"
                            name="active"
                            title="Status"
                            value={[undefined, null].includes(data?.active) ? undefined : data?.active ? "1" : "0"}
                            fieldType={InputFieldTypes.BOX}
                            options={[
                                { label: "Ativo", value: "1" },
                                { label: "Inativo", value: "0" },
                            ]}
                            onChange={({ active }) => handleChange({ active: active === "1" })}
                        />
                        <InputField
                            mb="lg"
                            name="update_block"
                            title="Bloquear permissão de edição de legenda"
                            value={data?.update_block ?? false}
                            fieldType={InputFieldTypes.SWITCH}
                            onChange={({ }) => handleChange({ update_block: !data?.update_block })}
                        />
                        {/* <Group mb="lg">
                        <Text style={{flex: 1}} size="sm" weight="bold">Aprovadores</Text>
                        <Button
                            onClick={() => setData(d => ({...d, approvers: [...(d.approvers || []), {}]}))}
                            color="lime" size="xs" variant='white' leftIcon={<FaPlus />}>Adicionar</Button>
                    </Group> */}
                        {/* {
                        (data?.approvers || []).map((approver, i) => )
                    } */}

                        <InputField
                            title="Notificar em grupo de Whatsapp"
                            value={data?.whatsapp_notification}
                            name="whatsapp_notification"
                            fieldType={InputFieldTypes.SELECT}
                            onChange={handleChange}
                            options={
                                whatsapps.filter(w => w.configured).map(wpp => ({ value: wpp._id, label: wpp?.data?.phone }))
                            }
                        />
                        {whatsapps.filter(w => w.configured).length === 0
                            ? <Text size="xs" c="red">Você precisa primeiro conectar o seu whatsapp</Text>
                            : <Text size="xs" c="yellow">Links enviados no grupo precisam de autenticação do usuário</Text>}

                        {data?.whatsapp_notification && <InputField
                            title="Selecione o grupo"
                            mt="md"
                            mb="md"
                            value={data?.whatsapp_group}
                            name="whatsapp_group"
                            rightSection={loadingGroups && <Loader />}
                            fieldType={InputFieldTypes.SELECT}
                            onChange={handleChange}
                            options={
                                whatsappGroups
                                    .sort((a, b) => a.creation > b.creation ? -1 : 1)
                                    .map(group => ({ value: group.id, label: group.subject }))
                            }
                        />}
                    </>
            }

            <Group position='right'>
                <Button variant='subtle' disabled={loadingSave} onClick={onClose}>Cancelar</Button>
                <Button variant='filled' loading={loadingSave} onClick={handleSave}>Salvar</Button>
            </Group>
        </Modal>
    </>
}
