import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Menu,
  Paper,
  Text, Title
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { PaymentForm } from "../components/payment_form";
import Table from "../components/table";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { GET_BILLING_DETAILS, REFUND_PAYMENT, SAVE_COMPANY_PAYMENT_METHOD, UNSUBSCRIBE_COMPANY } from "../services/payments";
import { formatMoney, getExtenseDatetime, useQuery } from "../utility/util";
import { FaEllipsisV } from "react-icons/fa";

export default function Billing({ checkout = false }) {
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [coupon, setCoupon] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [autoRecharge, setAutoRecharge] = useState<any>({});
  const [loadingUpdatePlan, setLoadingUpdatePlan] = useState<boolean>(false);
  const [loadingUpdateCoupon, setLoadingUpdateCoupon] = useState<boolean>(false);

  const autoRechargeLoadedRef = useRef(false);

  const { userData, loadUserData } = useAuth();
  const { confirmDialog, startPlan } = useApp();

  const loadDetails = () => {
    setLoading(true)
    GET_BILLING_DETAILS()
      .then((dt) => {
        setLoading(false)
        setData(dt);
        setAutoRecharge(dt.autoRecharge);
        autoRechargeLoadedRef.current = true;
      })
      .catch((err) => {
        setLoading(false)
        showNotification({ message: err.message, color: "red" })
      })
  }

  const handleUpdateMethod = (methodId, push) => {
    setLoadingUpdatePlan(true)
    SAVE_COMPANY_PAYMENT_METHOD({ payment: { method: methodId, push } })
      .then(res => {
        loadUserData();
        setLoadingUpdatePlan(false);
      })
      .catch(err => {
        setLoadingUpdatePlan(false)
        showNotification({ message: err.message, color: "red" })
      })
  }

  // const handleUpdateCoupon = () => {
  //   setLoadingUpdateCoupon(true)
  //   APPLY_COUPON(coupon)
  //   .then(res => {
  //     loadUserData();
  //     setLoadingUpdateCoupon(false);
  //     showNotification({ message: "Discount applied", color: "green" })
  //   })
  //   .catch(err => {
  //     setLoadingUpdateCoupon(false)
  //     showNotification({ message: err.message, color: "red" })
  //   })
  // }

  useEffect(() => {
    loadDetails()
  }, [])

  useEffect(() => {
    setPaymentMethod({ method: userData?.activePaymentMethod?._id });
  }, [userData])

  useEffect(() => {
    if (checkout && !userData?.activePaymentMethod) startPlan();
  }, [checkout, userData])

  return <>
    <LoadingOverlay visible={loading} />
    <Grid mb="md">
      {((userData?.coupon?.value ?? 0) < 100) && <Grid.Col md={6}>
        <Paper p="md" shadow="xs" style={{ minHeight: 320, display: 'flex', flexDirection: 'column' }}>
          <Title order={4}>Seu Plano</Title>
          {/* <Title order={5} fw="lighter" mb="md">{str("BILLING.YOUR_PLAN_SUBTITLE")}</Title> */}

          <Paper shadow='xs' p="md" mb="md" mt="xs" style={{ flex: 1, border: '1px solid #DFDFDF', cursor: 'pointer' }} onClick={() => startPlan()}>
            <Title order={4}>{userData?.plan?.title ?? "Nenhum plano ativo"}</Title>
            {userData?.plan && <>
              {/* <Text c="gray" size="sm">{formatMoney(userData?.plan?.price)}</Text> */}
              {/* <Text c="gray" size="xs">{(userData?.recurrence_type ?? "month")}</Text> */}
            </>}
            <Text c="gray" size="sm" mt="md">{(userData?.plan?.description ?? "").split("\n").map((d) => <div style={{ minHeight: 14 }}>{d}</div>)}</Text>
            {
              userData?.activePaymentMethod && <>
                <Text mt="md" size="xs" ta="right">Próxima Fatura: {moment(data?.nextPayment?.date).format("MMM DD, YYYY")}</Text>
                {(data?.nextPayment?.details ?? []).map(dt => (
                  <Text size="xs" ta="right">{dt.title}: {formatMoney(dt.value)}</Text>
                ))}
                <Text size="xs" ta="right" fw="bold">Total: {formatMoney(data?.nextPayment?.value)}</Text>
              </>
            }
          </Paper>
          <Group mt="md">
            <Text>Clientes: {data?.customers ?? 0}</Text>
            <div style={{ flex: 1 }}></div>
            <Button onClick={() => startPlan()}>Trocar Plano</Button>
          </Group>
        </Paper>
      </Grid.Col>}

      <Grid.Col md={6}>
        <Paper p="md" shadow="xs" style={{ minHeight: 320, display: 'flex', flexDirection: 'column' }}>
          <Title order={4}>Forma de Pagamento</Title>
          {/* <Title order={5} fw="lighter" mb="md"></Title> */}

          <Box style={{ flex: 1 }} mt="xs">
            <PaymentForm
              data={paymentMethod}
              methods={userData?.paymentMethods}
              onChange={(dt) => {
                setPaymentMethod(pm => ({ ...pm, ...dt }));
                if (dt.method) {
                  handleUpdateMethod(dt.method, false);
                }
              }}
              onSave={(methodId, isNew = false) => {
                setPaymentMethod({ method: methodId, push: isNew })
                setTimeout(() => handleUpdateMethod(methodId, isNew), 300);
              }}
            />
          </Box>

          {userData?.activePaymentMethod && <Button mt="md" color="white" variant="light" onClick={() => {
            confirmDialog({ text: "Realmente deseja cancelar sua assinatura?" }, ({ confirmed }) => {
              if (confirmed) UNSUBSCRIBE_COMPANY()
                .then(() => {
                  showNotification({ message: "Assinatura cancelada", color: "yellow" });
                  loadUserData();
                })
                .catch((err) => {
                  showNotification({ message: err.message, color: "red" })
                })
            })
          }}>Cancelar Assinatura</Button>}
          {/* <Group position="right">
              <Button loading={loadingUpdatePlan} onClick={handleUpdateMethod}>Atualizar</Button>
            </Group> */}
        </Paper>
      </Grid.Col>

      <Grid.Col md={12}>
        <Paper p="md" shadow="xs">
          <Title order={4} mb="md">Histórico</Title>

          {
            (data?.payments ?? []).filter(p => p.payed_at && !p.refund_at).length === 0
              ? <Text ta="center" mt="md" size="md" c="gray">Nenhum pagamento</Text>
              : <Table
                data={(data?.payments ?? []).filter(p => p.payed_at && !p.refund_at).sort((a, b) => a.payed_at > b.payed_at ? -1 : 1)}
                columns={[
                  {
                    title: "Data",
                    key: "createdAt",
                    render: (item) => item.createdAt ? getExtenseDatetime(item.createdAt) : ""
                  },
                  {
                    title: "Descrição",
                    key: "details",
                    render: (item) => item.details?.text
                  },
                  {
                    title: "Valor",
                    key: "value",
                    render: (item) => `${formatMoney(item.value)}`
                  },
                  userData?.user?.isAdmin && {
                    title: "Ações",
                    key: "actions",
                    render: (item) => <Menu>
                      <Menu.Target>
                        <ActionIcon><FaEllipsisV /></ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => {
                            REFUND_PAYMENT(item._id, item.value)
                              .then(() => {
                                loadDetails()
                                showNotification({ message: "Pagamento estornado", color: "green" })
                              })
                              .catch(err => {
                                showNotification({ message: err.message, color: "red" })
                              })
                          }}
                        >Estornar Pagamento</Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  },
                ].filter(nn => nn)}
              />
          }

        </Paper>
      </Grid.Col>
    </Grid>


  </>
};
