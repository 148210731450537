import { ActionIcon, Box, Checkbox, ColorInput, Grid, Group, Indicator, Loader, MultiSelect, PasswordInput, Progress, SegmentedControl, Select, Switch, Text, Textarea, TextInput, Title, TransferList, UnstyledButton, useMantineTheme } from '@mantine/core';
import { DatePickerInput, DateTimePicker, TimeInput } from '@mantine/dates';
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES, MS_EXCEL_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import React from 'react';
import { FaCheckCircle, FaEye, FaFileImage, FaTimesCircle, FaUpload, FaUser } from 'react-icons/fa';
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import { useFileUploader } from './file_uploader'
import { formatFileSize } from '../utility/util';
import { FaMinus, FaPlus } from 'react-icons/fa6';

export enum InputFieldTypes {
    STRING = "string",
    NUMBER = "number",
    SELECT = "select",
    TRANSFER = "transfer",
    IMAGE = "image",
    FILE = "file",
    DATE = "date",
    DATETIME = "datetime",
    TIME = "time",
    DATERANGE = "daterange",
    BOOLEAN = "boolean",
    EXCEL = "excel",
    SWITCH = "switch",
    TEXTAREA = "textarea",
    BOX = "box",
    COLOR = "color",
    PASSWORD = "password",
    QUANTITY = "quantity",
}
export interface InputFieldProps {
    name: string
    title?: string;
    value?: any;
    onChange?: any;
    loading?: boolean;
    options?: { value: string; label: string }[];
    fieldType?: InputFieldTypes;
    multiple?: boolean;
    mask?: InputFieldMasks;
    customMask?: string | string[];
    maskPlaceholder?: string;
    maskChar?: string;
    icon?: any;
    style?: any;
    styles?: any;
    minRows?: number;
    maxRows?: number;
    placeholder?: string;
    clearable?: boolean;
    searchable?: boolean;
    beforeMaskValueChange?: any;
    min?: number;
    max?: number;
}
export enum InputFieldMasks {
    MONEY = "money",
    CUSTOM = "custom",
}

export const UploadFile = ({ file, onFileUploaded, progressOnFinish = true }) => {
    const [uploaded, setUploaded] = React.useState(false);
    const { progress, error, name: filename, size } = useFileUploader({
        file,
        onFileUploaded: (props) => {
            onFileUploaded(props);
            setUploaded(true)
        },
    });

    return (progressOnFinish || !uploaded)
        ? <Box mb="md">
            <Progress
                value={progress} color="gray" size="xl"
                title={filename}
                sections={[
                    { value: progress, color: error ? "red" : progress === 100 ? "green" : "blue", label: filename },
                ]}
            />
            <Group>
                {error
                    ? <Text fw="bold" c="red" size="xs">{error}</Text>
                    : <Text fw="bold" c="gray" size="sm">{progress.toFixed(0)}%</Text>}
                <div style={{ flex: 1 }}></div>
                <Text c="gray" fw="bold" size="sm">{formatFileSize(size)}</Text>
            </Group>
        </Box>
        : null
}

export default function InputField(props: InputFieldProps & any) {
    const {
        name,
        value,
        mask,
        customMask,
        maskPlaceholder = " ",
        maskChar = " ",
        title,
        fieldType = InputFieldTypes.STRING,
        loading,
        onChange,
        options = [],
        minRows = 5,
        maxRows = 8,
        multiple,
        icon,
        style,
        placeholder,
        clearable = true,
        styles,
        beforeMaskValueChange,
        min,
        max,
        ...others
    } = props;
    const [files, setFiles] = React.useState([]);
    const theme = useMantineTheme();

    const [loadingUpload, setLoadingUpload] = React.useState(false);

    if ([InputFieldTypes.BOX].includes(fieldType)) {
        return <Box {...others}>
            {title && <Text size="sm" weight="bold">{title}</Text>}
            <SegmentedControl
                value={value}
                onChange={(vl) => onChange({ [name]: vl })}
                data={options}
                fullWidth
                color="lime"
            />
        </Box>
    }
    if (fieldType === InputFieldTypes.COLOR) {
        return <ColorInput
            label={title}
            value={value ?? undefined}
            title={title}
            placeholder={placeholder ?? title}
            style={style}
            styles={styles}
            onChange={(color) => onChange && onChange({ [name]: color })}
            {...others}
        />
    }
    if ([InputFieldTypes.TRANSFER].includes(fieldType)) {
        return <TransferList
            value={[
                (value || [])
                    .filter(r => (options || []).some(r2 => r2.value === r))
                    .map(r => ({ value: r, label: options.find(r2 => r2.value === r)?.label })),
                (options || []).filter(r => !(value || []).includes(r.value))
            ]}
            onChange={([items]) => onChange && onChange({ [name]: items.map(item => item.value) })}
            titles={["Ativos", "Opções"]}
            searchPlaceholder="Procurar..."
            nothingFound="Não encontrado"
            breakpoint="sm"
            title={title}
        />
    }
    if ([InputFieldTypes.SELECT].includes(fieldType)) {
        return multiple
            ? <MultiSelect
                label={title}
                value={value ? value : []}
                placeholder={placeholder || "Selecione..."}
                searchable
                data={options.map(opt => ({ label: `${opt.label}`, value: `${opt.value}` }))}
                clearable={clearable}
                onChange={(v) => onChange({ [name]: v })}
                {...others}
            />
            : <Select
                label={title}
                value={value ? `${value}` : null}
                placeholder={placeholder || "Selecione..."}
                searchable
                data={options.map(opt => ({ label: `${opt.label}`, value: `${opt.value}` }))}
                clearable={clearable}
                onChange={(v) => onChange({ [name]: v })}
                {...others}
                autoFocus={false}
                sx={{ ["& .mantine-Select-label"]: { fontWeight: "bold" } }}
            />
    }
    if ([InputFieldTypes.DATE].includes(fieldType)) {
        return <div>
            <DatePickerInput
                label={title}
                firstDayOfWeek={0}
                value={value ? value : null}
                closeOnChange
                valueFormat="DD/MM/YYYY"
                // dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
                clearable={clearable}
                // allowFreeInput
                onChange={(v) => onChange({ [name]: v })}
                {...others}{...others}
            />
        </div>
    }
    if ([InputFieldTypes.DATETIME].includes(fieldType)) {
        return <div>
            <DateTimePicker
                label={title}
                firstDayOfWeek={0}
                value={value ? value : null}
                placeholder={placeholder || "Selecione..."}
                valueFormat="DD/MM/YYYY HH:mm"
                dropdownType="modal"
                // dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
                clearable={clearable}
                // allowFreeInput
                onChange={(v) => onChange({ [name]: v })}
                {...others}
            />
        </div>
    }
    if ([InputFieldTypes.TIME].includes(fieldType)) {
        return <div>
            <TimeInput
                label={title}
                value={value ? value : null}
                placeholder={placeholder || "Selecione..."}
                onChange={(v) => onChange({ [name]: v.target.value })}
                {...others}
            />
        </div>
    }
    if ([InputFieldTypes.DATERANGE].includes(fieldType)) {
        return <div style={{ position: 'relative' }}>
            <DatePickerInput
                allowSingleDateInRange
                label={title}
                closeOnChange
                value={(value && value.length > 0) ? value : [null, null]}
                clearable={clearable}
                valueFormat="DD/MM/YYYY"
                type="range"
                renderDay={(date) => (
                    <Indicator size={6} color="red" offset={8} disabled={moment().format("YYYY-MM-DD") !== moment(date).format("YYYY-MM-DD")}>
                        <div>{date.getDate()}</div>
                    </Indicator>
                )}
                onChange={(v: any) => {
                    // const qt = v.filter(v => v).length;
                    onChange({ [name]: v })
                }}
                {...others}
            />
        </div>
    }
    if ([InputFieldTypes.BOOLEAN].includes(fieldType)) {
        return <Checkbox
            label={title}
            checked={value ? ["true", true].includes(value) : null}
            onChange={(v) => onChange({ [name]: !!v.target.checked })}
        />
    }
    if ([InputFieldTypes.IMAGE, InputFieldTypes.FILE, InputFieldTypes.EXCEL].includes(fieldType)) {
        return <div style={{ position: 'relative' }}>
            {title && <Text weight="bold" style={{ fontSize: 14, marginBottom: 4 }}>{title}</Text>}
            <Dropzone
                onDrop={(files) => {
                    console.log({ files })
                    setFiles(files)
                }}
                accept={
                    others.accept ||
                    {
                        [InputFieldTypes.IMAGE]: IMAGE_MIME_TYPE,
                        [InputFieldTypes.EXCEL]: MS_EXCEL_MIME_TYPE,
                    }[fieldType] || [
                        MIME_TYPES.csv, MIME_TYPES.doc, MIME_TYPES.doc, MIME_TYPES.docx,
                        MIME_TYPES.exe, MIME_TYPES.gif, MIME_TYPES.jpeg, MIME_TYPES.mp4,
                        MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.ppt, MIME_TYPES.svg,
                        MIME_TYPES.xls, MIME_TYPES.xlsx, MIME_TYPES.zip, MIME_TYPES.webp,
                    ]
                }
                multiple={multiple}
                style={{ margin: 0, padding: 0, ...style }}
                maxSize={2000000000}
                loading={loading}
                mt="lg"
            >
                <Group
                    position="center"
                    spacing="xl"
                    style={{ padding: 20, pointerEvents: "none" }}
                >
                    <Dropzone.Accept>
                        <div style={{ minWidth: 460 }}>
                            <FaUpload size={35} />
                            {((!!value && (!multiple || value.length > 0))) && (
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <FaCheckCircle size={35} />
                                    <div style={{ padding: '0 20px' }}>
                                        <Text size="md" inline>
                                            Arquivo adicionado
                                        </Text>
                                        <Text size="sm" color="dimmed" inline mt={7}>
                                            {value?.name || title}
                                        </Text>
                                    </div>
                                </div>)}
                        </div>
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <FaTimesCircle size={35} />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        {(!!value && (!multiple || value.length > 0))
                            ? <img style={{ height: '80px' }} src={value} />
                            : <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <FaFileImage size={35} />
                                <div style={{ flex: 1, padding: '0 20px' }}>
                                    <Text size="md" inline>
                                        Arraste ou clique para selecionar o arquivo
                                    </Text>
                                    <Text size="xs" color="dimmed" inline mt={7}>
                                        O arquivo não deve exceder 2GB
                                    </Text>
                                </div>
                            </div>
                        }
                    </Dropzone.Idle>
                </Group>
            </Dropzone>
            {files.map(file => <UploadFile
                file={file}
                progressOnFinish={false}
                onFileUploaded={({ url, type }) => {
                    onChange && onChange({ [name]: multiple ? [...(value ?? []), url] : url });
                }}
            />)}
            {!!loadingUpload
                ? <ActionIcon
                    size='xl'
                    color="lime"
                    variant="filled"
                    style={{ position: 'absolute', bottom: 5, right: 5 }}
                ><Loader size="xs" color="white" /></ActionIcon>
                : !!value && (!multiple || value.length > 0) && <ActionIcon
                    size='xl'
                    color="lime"
                    variant="filled"
                    onClick={() => multiple ? value.forEach(v => window.open(v, "_blank")) : window.open(value, "_blank")}
                    style={{ position: 'absolute', bottom: 5, right: 5 }}><FaEye /></ActionIcon>
            }
        </div>
    }
    if ([InputFieldTypes.SWITCH].includes(fieldType)) {
        return <Switch
            label={title}
            checked={!!value}
            onChange={() => onChange({ [name]: !value })}
            style={{ ...style, cursor: 'pointer', fontWeight: 'bold' }}
            {...others}
        />
    }
    if (fieldType === InputFieldTypes.TEXTAREA) {
        return <Textarea
            autosize
            minRows={minRows}
            maxRows={maxRows}
            label={title}
            value={value}
            title={title}
            placeholder={placeholder}
            icon={icon}
            style={style}
            styles={styles}
            onChange={(e) => onChange && onChange({ [name]: e.target.value })}
            {...others}
            sx={{ ["& .mantine-Textarea-label"]: { fontWeight: "bold" } }}
        />
    }
    if ([InputFieldTypes.QUANTITY].includes(fieldType)) {
        return <>
            {title && <Text size="sm">{title}</Text>}
            <Group {...others} align='center'>
                <ActionIcon disabled={min !== null && parseInt(value) <= min} size={"sm"} color='gray' variant='filled' onClick={() => {
                    if (min && (parseInt(value) - 1) <= min) onChange && onChange({ [name]: min });
                    else onChange && onChange({ [name]: value ? parseInt(value) - 1 : 0 });
                }}><FaMinus size="12px" /></ActionIcon>
                <Box style={{ width: 100 }}>
                    <InputField
                        name="vl"
                        value={value || "0"}
                        type="number"
                        size={"xs"}
                        placeholder={placeholder ?? title}
                        onChange={({ vl }) => {
                            vl = isNaN(vl) ? 0 : parseInt(vl || "0");
                            if (min && parseInt(vl) <= min) onChange && onChange({ [name]: min });
                            else if (max && parseInt(vl) >= max) onChange && onChange({ [name]: max });
                            else onChange && onChange({ [name]: parseInt(vl) });
                        }}
                        readOnly
                        {...others}
                        style={{ ...style, cursor: 'pointer', fontWeight: 'bold' }}
                        styles={{ input: { textAlign: 'center' } }}
                    />
                    {others.centerSection}
                </Box>
                <ActionIcon disabled={max !== null && parseInt(value) >= max} size={"sm"} color='gray' variant='filled' onClick={() => {
                    if (max && (parseInt(value) + 1) >= max) onChange && onChange({ [name]: max });
                    else onChange && onChange({ [name]: value ? parseInt(value) + 1 : 1 });
                }}><FaPlus size="12px" /></ActionIcon>
            </Group>
        </>
    }
    return <>
        <TextInput style={{ display: 'none' }} />
        {
            mask === InputFieldMasks.MONEY
                ? <div className="mantine-TextInput-root mantine-1oip40b" style={style}>
                    <div className="mantine-TextInput-wrapper mantine-12sbrde">
                        <NumberFormat
                            value={value ? parseFloat(value) : ""}
                            displayType={'input'}
                            thousandSeparator='.'
                            decimalSeparator=","
                            customInput={TextInput}
                            decimalScale={2}
                            fixedDecimalScale
                            prefix={'R$ '}
                            onValueChange={(values) => {
                                const { value } = values;
                                onChange && onChange({ [name]: value });
                            }}
                            inputMode="decimal"
                            label={title}
                            placeholder={placeholder ?? title}
                            style={style}
                            styles={styles}
                            {...others}
                            sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
                            type={fieldType === InputFieldTypes.NUMBER ? "number" : others.type}
                        />
                    </div>
                </div>
                : mask === InputFieldMasks.CUSTOM
                    ? <InputMask
                        mask={customMask}
                        value={props.value}
                        maskPlaceholder={maskPlaceholder}
                        maskChar={maskChar}
                        onChange={(e) => onChange && onChange({ [name]: e.target.value })}
                        beforeMaskedValueChange={beforeMaskValueChange}
                    >
                        {(props) =>
                            <TextInput
                                label={title}
                                value={value}
                                title={title}
                                placeholder={placeholder}
                                icon={icon}
                                style={style}
                                styles={styles}
                                {...others}
                                {...props}
                            />}
                    </InputMask>
                    : fieldType === InputFieldTypes.PASSWORD
                        ? <PasswordInput
                            label={title}
                            value={value ? value : ""}
                            title={title}
                            placeholder={placeholder}
                            icon={icon}
                            style={style}
                            styles={styles}
                            onChange={(e) => onChange && onChange({ [name]: e.target.value })}
                            {...others}
                            sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
                        />
                        : <TextInput
                            label={title}
                            value={value ? value : ""}
                            title={title}
                            placeholder={placeholder}
                            icon={icon}
                            style={style}
                            styles={styles}
                            onChange={(e) => onChange && onChange({ [name]: e.target.value })}
                            {...others}
                            sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
                        />
        }
    </>
} 