import { ActionIcon, Box, Button, Grid, Group, Loader, Paper, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaCheck, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import InputField, { InputFieldTypes } from '../components/input_field';
import { PaymentForm } from '../components/payment_form';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { SEARCH_COUPON } from '../services/auth';
import { SAVE_COMPANY_PLAN } from '../services/companies';
import { GET_BILLING_DETAILS } from '../services/payments';
import { GET_ALL_PLANS } from '../services/plans';
import { formatMoney, useQuery } from '../utility/util';

export default function ChoosePlan({ onClose = null }) {
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [plans, setPlans] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({});
    const [searchCoupon, setSearchCoupon] = useState<string>("");
    const [selectedRecurrencyType, setSelectedRecurrencyType] = useState<string>("1-year");
    const [coupons, setCoupons] = useState<any>({});
    const [billing, setBilling] = useState<any>(null);

    const selectedPlan = plans.find(s => s._id === formData?.plan);

    const { startPlan } = useApp();
    const { planData, userData, loadUserData } = useAuth();
    const query = useQuery();
    const cupom = query.get("cupom")

    let billingFileSize = (billing?.filesSize ?? 0);
    let planMaxStorage = (selectedPlan?.max_storage ?? 0);
    let minStorage = billingFileSize > planMaxStorage ? billingFileSize : planMaxStorage;
    let formStorage = (formData?.storage ?? 0);
    if (minStorage < planMaxStorage) minStorage = planMaxStorage;
    minStorage = Math.ceil((minStorage / 1000)) * 1000;
    let extraStorage = formStorage - planMaxStorage;

    let billingCustomers = (billing?.customers ?? 0);
    let planMaxCustomers = (selectedPlan?.max_customers ?? 0)
    let minCustomers = billingCustomers > planMaxCustomers ? billingCustomers : planMaxCustomers;
    let addCustomers = (formData?.customers ?? 0) - planMaxCustomers;

    let vl = parseFloat(selectedPlan?.price ?? 0);
    if (addCustomers > 0) vl += parseFloat(selectedPlan?.add_on_customer ?? 0) * addCustomers;
    if (extraStorage > 0) vl += parseFloat(selectedPlan?.add_on_storage ?? 0) * extraStorage;
    vl = vl * {
        "month": 1,
        "year": 12,
    }[selectedPlan?.recurrence_type ?? "month" as string] * selectedPlan?.recurrence_period;
    if (coupons[searchCoupon]) vl = coupons[searchCoupon].type === "static"
        ? vl - coupons[searchCoupon].value
        : vl * ((100 - coupons[searchCoupon].value) / 100);

    useEffect(() => {
        console.log({ extraStorage, minStorage, planMaxStorage, selectedPlan })
    }, [extraStorage, minStorage, planMaxStorage, selectedPlan])

    // const handleSave = async () => {
    //     if(formData?.payment?.method){
    //         setLoadingSave(true);
    //         if(formData?.payment?.method === "new-card"){
    //             try{
    //                 // const { awaz } = await GET_PAYMENT_CONFIGS("aprova-ai")

    //             }catch(err){
    //                 setLoadingSave(false)
    //                 showNotification({ message: err?.message ?? "Stripe not configured" });
    //             }
    //         }else{
    //             finishSave(formData?.payment?.method);
    //         }
    //     }else{
    //         showNotification({ message: "Selecione uma forma de pagamento" });
    //     }
    // }

    const loadBilling = () => {
        GET_BILLING_DETAILS()
            .then((dt) => {
                setBilling(dt);
            })
            .catch((err) => {
                showNotification({ message: err.message, color: "red" })
            })
    }

    const finishSave = () => {
        setLoadingSave(true);

        SAVE_COMPANY_PLAN({ ...formData, coupon: coupons[searchCoupon] })
            .then(res => {
                setLoadingSave(false);
                startPlan(false);
                loadUserData();
                // if(res.payed_at){
                // if(selectedPlan){
                //     (window as any).fbq('track', 'Subscribe', {
                //         // ip,
                //         name: userData?.user?.name,
                //         email: userData?.user?.email,
                //         phone: `+${userData?.user?.phone_code}${userData?.user?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
                //         plan: selectedPlan?.title,
                //         currency: selectedPlan?.currency ?? "BRL",
                //         value: selectedPlan?.price
                //     });
                // }
                // }else{
                //     if(res.return_url) window.open(res.return_url, "_blank");
                //     else showNotification({ message: "You need to finish the payment process in your bank. Check for notifications and approve the transaction to finish", color: "yellow" })
                // }
            })
            .catch(err => {
                setLoadingSave(false)
                showNotification({ message: err.message, color: "red" })
            })
    }

    const loadPlans = () => {
        setLoadingPlans(true);

        GET_ALL_PLANS()
            .then(res => {
                setPlans(res);
                setLoadingPlans(false);
            })
            .catch(err => {
                setLoadingPlans(false)
                showNotification({ message: err.message, color: "red" })
            })
    }

    useEffect(() => {
        loadPlans();
        loadBilling();
    }, []);

    useEffect(() => {
        setFormData(fd => ({ ...fd, installments: selectedPlan?.max_installments ?? "1" }))
    }, [selectedPlan]);

    useEffect(() => {
        if (cupom && cupom.length > 3) setSearchCoupon(cupom);
    }, [cupom]);

    useEffect(() => {
        if (searchCoupon.length > 3) SEARCH_COUPON(searchCoupon)
            .then(res => {
                setCoupons(c => ({ ...c, [searchCoupon]: res }))
            })
            .catch(err => {
            })
    }, [searchCoupon]);

    useEffect(() => {
        setFormData(fd => ({
            ...fd,
            customers: minCustomers,
            storage: minStorage,
        }))
    }, [billing, planData, minStorage, minCustomers]);

    return <Box p="md">
        {!selectedPlan && <>
            <Group align='flex-start'>
                <div style={{ flex: 1 }}>
                    <Title order={3}>Escolha Seu Plano</Title>
                    {/* <Title order={5} style={{fontWeight: 'normal'}}></Title> */}
                </div>
                {onClose && <ActionIcon onClick={onClose} variant='outline'>
                    <FaTimes />
                </ActionIcon>}
            </Group>

            <Group pb="md" pt="md" style={{ justifyContent: 'center' }} align='center'>
                <Group spacing={5} style={{ borderRadius: 40, padding: '10px', border: '1px solid #DFDFDF', whiteSpace: 'nowrap' }}>
                    {
                        plans
                            .filter(p => `${(p.recurrence_period ?? 1)}-${(p.recurrence_type ?? "month")}` === "1-year")
                            .length > 0 &&
                        <Button
                            style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "1-year" ? '1px solid #DFDFDF' : 0 }}

                            onClick={() => setSelectedRecurrencyType("1-year")}
                            variant={selectedRecurrencyType === "1-year" ? "filled" : "light"} size="xs">Anual</Button>}

                    {
                        plans
                            .filter(p => `${(p.recurrence_period ?? 1)}-${(p.recurrence_type ?? "month")}` === "6-month")
                            .length > 0 &&
                        <Button
                            style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "6-month" ? '1px solid #DFDFDF' : 0 }}

                            onClick={() => setSelectedRecurrencyType("6-month")}
                            variant={selectedRecurrencyType === "6-month" ? "filled" : "light"} size="xs">Semestral</Button>}

                    {
                        plans
                            .filter(p => `${(p.recurrence_period ?? 1)}-${(p.recurrence_type ?? "month")}` === "3-month")
                            .length > 0 &&
                        <Button
                            style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "3-month" ? '1px solid #DFDFDF' : 0 }}

                            onClick={() => setSelectedRecurrencyType("3-month")}
                            variant={selectedRecurrencyType === "3-month" ? "filled" : "light"} size="xs">Trimestral</Button>}

                    {
                        plans
                            .filter(p => `${(p.recurrence_period ?? 1)}-${(p.recurrence_type ?? "month")}` === "1-month")
                            .length > 0 &&
                        <Button
                            style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "1-month" ? '1px solid #DFDFDF' : 0 }}

                            onClick={() => setSelectedRecurrencyType("1-month")}
                            variant={selectedRecurrencyType === "1-month" ? "filled" : "light"} size="xs">Mensal</Button>}

                </Group>
            </Group>

            {loadingPlans && <Loader mt="xl" />}
            <Grid mt="md" mb="md">
                {plans
                    .filter(p => `${(p.recurrence_period ?? 1)}-${(p.recurrence_type ?? "month")}` === selectedRecurrencyType)
                    .sort((a,b) => a.add_on_customer > b.add_on_customer ? 1 : -1)
                    .map(plan => (
                        <Grid.Col md={4}>
                            <UnstyledButton style={{ width: '100%' }} onClick={() => {
                                setFormData(fd => ({ ...fd, plan: plan._id }))
                            }}>
                                <Paper shadow='xs' p="md" style={{ borderColor: '#DFDFDF' }}>
                                    <Title order={3}>{plan.title}</Title>
                                    <Text c="gray" size="sm" mt="sm">{(plan.description ?? "").split("\n").map((d) => <div style={{ minHeight: 14 }}>{d}</div>)}</Text>

                                    {
                                        (plan.max_installments ?? 1) > 1
                                            ? <Box mt="md">
                                                <Title size="22px">{plan.max_installments ?? 1} x {formatMoney((plan.price ? plan.price : plan.add_on_customer))}</Title>
                                                <Text size="sm">ou {formatMoney((plan.price ? plan.price : plan.add_on_customer) * (plan.max_installments ?? 1))} à vista</Text>
                                            </Box>
                                            : <Title mt="md" size="22px">{formatMoney((plan.price ? plan.price : plan.add_on_customer))}</Title>
                                    }
                                    {/* <Text mt="md" size="sm">Válido por {plan.recurrence_period ?? 1} {extenseRecurrenceTypeDescription(plan.recurrence_type ?? "month", (plan.recurrence_period ?? 1) > 1).toLowerCase()}</Text> */}
                                    {/* <Group align='center' justify='flex-end' mt="xs">
                                    {(plan.service.badges ?? []).map(s => ( <Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge> ))}
                                </Group> */}
                                    {/* <Text c="gray" size="sm">Per user</Text> */}
                                    <Button mt="md" fullWidth>Confirmar</Button>
                                </Paper>
                            </UnstyledButton>
                        </Grid.Col>
                    ))}
            </Grid>
        </>}

        {selectedPlan && <>
            <Grid>
                <Grid.Col md={12}>
                    <Group>
                        <ActionIcon size="xl" variant="outline" color="gray" onClick={() => setFormData(fd => ({ ...fd, plan: null }))}><FaArrowLeft /></ActionIcon>
                        <Title order={3}>Checkout</Title>
                    </Group>
                </Grid.Col>
                <Grid.Col md={12}>
                    <Paper shadow='xs' p="xl">
                        <Group align="flex-start">
                            <Box style={{ flex: 0.6 }}>
                                <Title order={3}>{selectedPlan.title}</Title>
                                <Text size="sm">{selectedPlan.description}</Text>
                            </Box>
                            <Box style={{ flex: 0.4 }}>
                                <Group>
                                    <Box style={{ flex: 1 }}>
                                        <Group align='flex-end' spacing="1px">
                                            <Title size="22px">{formatMoney(selectedPlan.add_on_customer ?? 0)}</Title>
                                            {/* <Text c="gray" size="xs">{extenseRecurrenceType("month")}</Text> */}
                                        </Group>
                                        <Text mb="md" c="gray" size="sm">Por cliente por mês</Text>
                                        <InputField
                                            title="Total de Clientes"
                                            name="customers"
                                            fieldType={InputFieldTypes.QUANTITY}
                                            value={formData.customers}
                                            min={minCustomers}
                                            onChange={({ customers }) => {
                                                let minimun = minCustomers;
                                                minimun = (minimun > 1 ? minimun : 1);
                                                if (parseInt(customers) >= minimun) {
                                                    setFormData(fd => ({ ...fd, customers }));
                                                }
                                            }}
                                            size="xs"
                                        />
                                    </Box>
                                    <Box style={{ flex: 1 }}>
                                        <Group align='flex-end' spacing="1px">
                                            <Title size="22px">{formatMoney(selectedPlan?.add_on_storage ?? 0)}</Title>
                                            {/* <Text c="gray" size="xs">{extenseRecurrenceType("month")}</Text> */}
                                        </Group>
                                        <Text mb="md" c="gray" size="sm">Por GB por mês</Text>
                                        <InputField
                                            title="Armazenamento (GB)"
                                            name="storage"
                                            fieldType={InputFieldTypes.QUANTITY}
                                            value={formData.storage / 1000}
                                            min={minStorage / 1000}
                                            onChange={({ storage }) => {
                                                let minimun = minStorage / 1000;
                                                minimun = (minimun > 1 ? minimun : 1);
                                                if (parseInt(storage) >= minimun) {
                                                    setFormData(fd => ({ ...fd, storage: storage * 1000 }));
                                                }
                                            }}
                                            size="xs"
                                        />
                                    </Box>
                                </Group>

                                <InputField
                                    title={"Cupom de Desconto"}
                                    name="cp"
                                    mt="sm"
                                    size={"md"}
                                    value={searchCoupon}
                                    onChange={({ cp }) => { setSearchCoupon(cp) }}
                                    rightSection={coupons[searchCoupon] && <FaCheck />}
                                />
                            </Box>

                        </Group>
                    </Paper>
                </Grid.Col>
                <Grid.Col md={7}>
                    <Paper shadow='xs' p="xl">
                        {
                            <>
                                <Title order={4}>Forma de Pagamento</Title>
                                <Box mt="sm">
                                    <PaymentForm
                                        data={formData?.payment}
                                        methods={userData.paymentMethods}
                                        onChange={(dt) => {
                                            setFormData(fd => ({
                                                ...fd,
                                                payment: { ...fd?.payment, ...dt }
                                            }))
                                        }}
                                        onSave={(methodId, isNew = false) => {
                                            setFormData(fd => ({ ...fd, payment: { ...fd?.payment, method: methodId, push: isNew } }))
                                        }}
                                    />
                                    {(selectedPlan?.max_installments ?? 1) > 1 && <InputField
                                        name='installments'
                                        title="Parcelamento"
                                        fieldType={InputFieldTypes.SELECT}
                                        onChange={({ installments }) => setFormData(fd => ({ ...fd, installments }))}
                                        value={formData?.installments ?? "1"}
                                        options={
                                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                                                .filter(i => i <= (selectedPlan?.max_installments ?? 1))
                                                .map(i => ({
                                                    label: `${i} x ${formatMoney((vl - (billing?.availableCredits ?? 0)) / i)}`, value: `${i}`,
                                                }))
                                        }
                                    />}
                                </Box>
                            </>
                        }
                    </Paper>
                </Grid.Col>
                <Grid.Col md={5}>
                    <Paper shadow='xs' p="xl">
                        <Stack>
                            <Title order={4}>Total</Title>
                            {/* <Paper p="md" shadow='xs'>
                                <Text fw="bold" size="xs">Max. de {selectedPlan?.max_customers} Clientes</Text>
                                <Text size="xs" mt="xs" c="gray">{formatMoney(selectedPlan?.add_on_customer ?? 0)} adicional</Text>
                            </Paper> */}
                            <Paper p="md" shadow='xs'>
                                <Text fw="bold" size="xs">{[
                                    `Assinatura ${formData?.customers > 1 ? ` (${formData?.customers} clientes)` : formData?.customers > 0 ? `(1 cliente)` : ""}`,
                                    extraStorage > 0 ? `${extraStorage / 1000}GB de armazenamento adicional` : ""
                                ].filter(nn => nn).join(" + ")}</Text>
                                <Text size="xs" mt="xs">{formatMoney(vl)}</Text>
                            </Paper>
                            {(billing?.availableCredits ?? 0) > 0 && <Paper p="md" shadow='xs'>
                                <Text fw="bold" size="xs">Créditos Disponíveis</Text>
                                <Text size="xs" mt="xs">{formatMoney(billing?.availableCredits)}</Text>
                            </Paper>}
                            <Paper p="md" shadow='xs'>
                                <Text fw="bold" size="xs">Total</Text>
                                <Text size="xs" mt="xs">{formData?.installments ?? 1} x {formatMoney((vl - (billing?.availableCredits ?? 0)) / (formData?.installments ?? 1))}</Text>
                            </Paper>
                        </Stack>
                    </Paper>

                </Grid.Col>
                <Grid.Col md={12}>
                    <Button disabled={!formData?.payment?.method} loading={loadingSave} onClick={finishSave} mt="lg" variant="filled" fullWidth size="lg" rightIcon={<FaLongArrowAltRight />}>Confirmar</Button>
                </Grid.Col>
            </Grid>
        </>}
    </Box>
}
