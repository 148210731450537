import { ActionIcon, Avatar, Box, Card, Grid, Group, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import moment from 'moment';
import React from "react";
import { momentLocalizer } from 'react-big-calendar';
import { FaChartPie, FaClock, FaComment, FaPlus, FaThumbsUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { JobsCalendar } from '../components/jobs_calendar';
import SimpleHeader from '../components/simple_header';
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";

const localizer = momentLocalizer(moment)

export default function Home() {

    const navigate = useNavigate();
    const { role } = useAuth();
    const { configs, jobs } = useApp();

    const qtToApprove = (jobs.filter(j => !j.archived && ["draft", "to-approve"].includes(j.statusKey)).length);
    const qtAdjust = (jobs.filter(j => !j.archived && ["adjust"].includes(j.statusKey)).length)
    const qtApproved = (jobs.filter(j => !j.archived && ["approved"].includes(j.statusKey)).length)

    return <div style={{ position: 'relative' }}>
        <SimpleHeader
            title="Dashboard"
            subtitle="Página inicial"
            button={role && { title: "Adicionar Job", leftIcon: <FaPlus />, size: 'md', onClick: () => { navigate("/jobs/novo") } }} />

        <Grid>
            <Grid.Col xs={12} md={6}>
                <Paper shadow="xs" style={{ minHeight: 380 }}>
                    <Box style={{ background: configs?.primary, borderRadius: 4 }}>
                        <Title p="xl" order={2} weight="bold" color="white">Overview de jobs</Title>
                        <div style={{ height: 40 }}></div>
                    </Box>
                    <div style={{ marginTop: -60 }}>
                        <Grid p="lg">
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{ cursor: 'pointer' }} onClick={() => navigate("/jobs")}>
                                    <Group style={{ justifyContent: 'center' }}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaClock /></ActionIcon>
                                        <Title order={2}>{qtToApprove}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Jobs pendentes</Title>
                                </Paper>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{ cursor: 'pointer' }} onClick={() => navigate("/jobs")}>
                                    <Group style={{ justifyContent: 'center' }}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaComment /></ActionIcon>
                                        <Title order={2}>{qtAdjust}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Pedidos de ajuste</Title>
                                </Paper>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{ cursor: 'pointer' }} onClick={() => navigate("/jobs")}>
                                    <Group style={{ justifyContent: 'center' }}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaThumbsUp /></ActionIcon>
                                        <Title order={2}>{qtApproved}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Aprovados pelo cliente</Title>
                                </Paper>
                            </Grid.Col>
                            <Grid.Col xs={12} md={6}>
                                <Paper shadow="xs" p="xl" style={{ cursor: 'pointer' }} onClick={() => navigate("/jobs")}>
                                    <Group style={{ justifyContent: 'center' }}>
                                        <ActionIcon color={configs?.primary ?? "green"} size="lg" variant="filled"><FaChartPie /></ActionIcon>
                                        <Title order={2}>{jobs.length}</Title>
                                    </Group>
                                    <Title align="center" order={5} color="gray" mt="xs">Total de jobs realizados</Title>
                                </Paper>
                            </Grid.Col>
                        </Grid>
                    </div>
                </Paper>
            </Grid.Col>
            {role && role?.profile?.title === "Administrador" && <Grid.Col xs={12} md={6}>
                <CustomersPaper />
            </Grid.Col>}
            <Grid.Col md={12}>
                <JobsCalendar jobs={jobs} />
            </Grid.Col>
        </Grid>
    </div>
}

const CustomersPaper = () => {
    const [loadingCustomers, setLoadingCustomers] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const { customers } = useApp();

    return <Paper shadow="xs" p="xl" style={{ minHeight: 380 }}>
        <Title mb="xl" order={4}>Clientes</Title>
        <Group align="flex-start" style={{ justifyContent: 'center' }}>
            {customers.filter(c => c.active).slice(0, 11).map(c => (
                <UnstyledButton onClick={() => navigate(`/jobs?customer=${c._id}`)}>
                    <Group style={{ width: 100, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }}>
                        <Card style={{ padding: 0 }} shadow="xs"><Avatar size="lg" src={c.image} /></Card>
                        <Text align="center" size="xs" color="lime">{c.name}</Text>
                    </Group>
                </UnstyledButton>
            ))}
            <UnstyledButton onClick={() => navigate("/clientes/novo")}>
                <Group style={{ width: 100, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <ActionIcon color="lime" variant="outline" size="xl"><FaPlus /></ActionIcon>
                    <Text align="center" size="xs" color="lime">Adicionar Cliente</Text>
                </Group>
            </UnstyledButton>
        </Group>
    </Paper>
}