import { Image } from '@mantine/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import RenderImage from '../components/render_image';

export const getDriveData = async (fileId, accessToken) => {
    try {
        const dataUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=mimeType,hasThumbnail,thumbnailLink,webViewLink&access_token=${accessToken}`;
        const { data: { mimeType, hasThumbnail, webViewLink: value, thumbnailLink: thumb } } = await axios.get(dataUrl);
        const type = {
            "application": "file",
            "image": "image",
            "video": "video",
            "audio": "audio",
        }[(mimeType ?? "").split("/")[0]] ?? "file";
        return { id: fileId, mimeType, type, thumb: hasThumbnail ? thumb : undefined, value };
    } catch (error) {
        console.log(error)
        return { error: "Esse arquivo não existe ou não é público." }
    }
}

export const getDriveMedia = async (fileId, accessToken) => {
    try {
        const dataUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
        const { data } = await axios.get(dataUrl, {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
        });
        return data;
    } catch (error) {
        console.log(error)
        return { error: "Esse arquivo não existe ou não é público." }
    }
}

export const DriveThumb = ({
    width = 84,
    height = 84,
    fileId,
    accessToken,
}) => {
    const [thumb, setThumb] = useState(null);

    useEffect(() => {
        if (fileId && accessToken) {
            (async () => {
                try{
                    const { thumb } = await getDriveData(fileId, accessToken);
                    const { data: thumbBlob } = await axios.get(thumb, {
                        headers: { Authorization: `Bearer ${accessToken}` },
                        responseType: "blob",
                    });
                    setThumb(URL.createObjectURL(thumbBlob));
                }catch(err){
                    console.log(err);
                }
            })();
        }
    }, [fileId, accessToken])

    return <Image width={width} height={height} src={thumb} />

}

export const DriveContent = ({
    fileId,
    accessToken,
    height = 400,
}) => {
    const [type, setType] = useState(null);
    const [media, setMedia] = useState(null);

    useEffect(() => {
        if (fileId && accessToken) {
            (async () => {
                const { type } = await getDriveData(fileId, accessToken);
                const fileBlob = await getDriveMedia(fileId, accessToken);
                setType(type);
                setMedia(URL.createObjectURL(fileBlob));
            })();
        }
    }, [fileId, accessToken])

    if (type === "image") {
        return <RenderImage height={height} src={media} />;
    } else {
        return <embed src={media} type="application/pdf" width="100%" height="100%" />;
    }

}