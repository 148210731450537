import { Box, Grid, Group, Image, Paper, Stack, Text, UnstyledButton } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth.context';

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { months } from '../utility/util';
import moment from 'moment';
import { GET_LOGS, GET_REPORTS } from '../services/admin';
import { showNotification } from '@mantine/notifications';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function AdminPayments() {
    const { role } = useAuth();
    const [data, setData] = useState<any>({});
    const [history, setHistory] = useState<any[]>([]);

    const loadData = () => {
        GET_REPORTS()
            .then(setData)
            .catch(err => {
                showNotification({ message: err.message, color: "red" })
            })
    }

    const loadHistory = () => {
        GET_LOGS()
            .then(setHistory)
            .catch(err => {
                showNotification({ message: err.message, color: "red" })
            })
    }

    useEffect(loadData, [])
    useEffect(loadHistory, [])

    return <Grid align='flex-start'>
        {(data?.numbers ?? []).map(nb => <Grid.Col span={4}>
            <Paper style={{ backgroundColor: '#EFEFEF' }} p="xl">
                <Text ta="center" size="30px">{nb?.value}</Text>
                <Text ta="center" size="lg" color="gray" mt="md">{nb?.title}</Text>
            </Paper>
        </Grid.Col>)}
        <Grid.Col span={8}>
            <Stack>
                {(data.graphs ?? []).map(gr => {
                    let Component = {
                        "bar": Bar,
                    }[gr.type as string] ?? Line;
                    return <Paper style={{ backgroundColor: '#EFEFEF' }} p="xl">
                        <Component
                            options={{
                                responsive: true,
                                scales: {
                                    y:
                                    {
                                        min: gr.min,
                                        max: gr.max,
                                    },
                                },
                                plugins: {
                                    legend: {
                                        position: 'top' as const,
                                    },
                                    title: {
                                        display: true,
                                        text: gr.title,
                                    },
                                },
                            }
                            }
                            data={{
                                labels: (gr.labels ?? []).map(l => l.title),
                                datasets: (gr.data ?? []).map(dt => ({
                                    label: dt.title,
                                    data: (gr.labels ?? []).map((l, i) => l[dt.key] ?? 0),
                                    borderColor: `${dt.color}`,
                                    backgroundColor: `${dt.color}87`,
                                }))
                            }}
                        />
                    </Paper>
                })}
            </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
            <Paper style={{ backgroundColor: '#EFEFEF' }} p="xl">
                <Stack>
                    {
                        (history ?? []).map(log => (
                            <UnstyledButton>
                                <Paper>
                                    <Group>
                                        <Box style={{ flex: 1 }} p="md">
                                            <Text size="sm">{log.title}</Text>
                                            <Text size="sm" c="gray">{log.subtitle}</Text>
                                        </Box>
                                        <Box p="md">
                                            <Text ta="right" size="xs" c="gray">{moment(log.datetime).format("DD/MM/YYYY")}</Text>
                                            <Text ta="right" size="xs" c="gray">{moment(log.datetime).format("HH:mm")}</Text>
                                        </Box>
                                    </Group>
                                </Paper>
                            </UnstyledButton>
                        ))
                    }
                </Stack>
            </Paper>
        </Grid.Col>
    </Grid>
}
