import { Box, Button, Group, Loader, Table as MantineTable, ScrollArea } from '@mantine/core';
import React from 'react';
import { ActionProps, ActionsRender } from '../components/actions_render';

interface TableProps<T> {
    loading?: boolean;
    error?: string;
    data: T[];
    leftIcon?: any;
    columns: { key: string; title: string | (() => React.JSX.Element); render?: (item: T, index?: number) => any }[];
    actions?: ActionProps[];
    [key: string]: any;
}
const RenderTitle: React.FC<{ title: any }> = ({ title }) => {
    return <>{typeof title === "function" ? title() : title}</>;
};
export default function Table(props: TableProps<any>) {
    const { actions = [], columns = [], loading = false, error, ...others } = props;
    if (error) return <Group style={{ color: "#9a9a9a", padding: '40px 0' }}>Falha ao carregar: {error}</Group>;

    return <>
        <div style={{ padding: '10px 0' }}>
            {loading && <Loader />}
        </div>
        <ScrollArea>
            <MantineTable
                {...others}
                verticalSpacing="xs"
                striped
                highlightOnHover
                style={{ width: '100%' }}
            >
                <thead>
                    <tr>
                        {columns.map(column => <th key={column.key} style={{ whiteSpace: 'nowrap', minWidth: 50 }}><RenderTitle title={column.title} /></th>)}
                        {actions.length > 0 && <th style={{ minWidth: '5%' }}></th>}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item, index) => <tr key={`${index}`} style={{ backgroundColor: 'white' }}>
                        {columns.map(column => <td>{column.render ? column.render(item, index) : item[column.key]}</td>)}
                        {actions.length > 0 && <td><ActionsRender
                            actions={actions}
                            item={item}
                            index={index}
                        /></td>}
                    </tr>
                    )}
                </tbody>
            </MantineTable>
        </ScrollArea>
    </>
}