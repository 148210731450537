import { Box, Button, Group, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField, { InputFieldTypes } from '../components/input_field';
import { useApp } from '../contexts/app.context';
import { REQUEST_JOB } from '../services/jobs';

export default function RequestJob() {

    const [data, setData] = useState<any>({
        tasks: [],
    });
    const [description, setDescription] = useState<string>("");
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const navigate = useNavigate();
    const { customers, loadCustomers } = useApp();

    const handleChange = dt => setData(d => ({ ...d, ...dt }));

    const handleSave = () => {
        setLoadingSave(true)
        REQUEST_JOB({ ...data, tasks: [{ name: data?.name, description }] })
            .then((res) => {
                setLoadingSave(false)
                showNotification({ message: "Solicitação enviada!", color: 'green' })
                navigate("/jobs");
            })
            .catch(err => {
                setLoadingSave(false)
                showNotification({ title: "Ops", message: err.message, color: 'red' })
            });
    }

    useEffect(() => {
        setData(d => ({
            ...d,
            customer: customers[0]?._id,
        }))
    }, [customers]);

    useEffect(loadCustomers, [])

    return <>
        <Paper p="xl" shadow='xs'>
            <Group style={{ flexDirection: 'column', alignItems: 'unset' }}>
                <Group style={{ flexDirection: 'row' }}>
                    <Box style={{ flex: 1 }}>
                        <InputField
                            name="customer"
                            title="Conta *"
                            clearable={false}
                            searchable={false}
                            fieldType={InputFieldTypes.SELECT}
                            options={customers.filter(c => c.active).map(c => ({ value: c._id, label: c.name }))}
                            value={data?.customer?._id ?? data?.customer}
                            onChange={handleChange}
                        />
                    </Box>

                    <Box style={{ flex: 1 }}>
                        <InputField
                            name="deadline"
                            title="Prazo de Entrega"
                            // error={moment(data?.publish_date).diff(moment(data?.deadline), "days") < 0 ? "Aprovação deveria ser anterior que a data de publicação" : null}
                            fieldType={InputFieldTypes.DATETIME}
                            value={data?.deadline ? moment(data?.deadline).toDate() : undefined}
                            onChange={({ deadline }) => {
                                handleChange({ deadline: deadline ? moment(deadline).format() : undefined })
                            }}
                        />
                    </Box>
                </Group>

                <InputField
                    name="name"
                    title="Solicitação *"
                    value={data?.name}
                    onChange={handleChange}
                />

                <InputField
                    name="name"
                    title="Descreva mais sobre sua solicitação"
                    value={description}
                    fieldType={InputFieldTypes.TEXTAREA}
                    onChange={({ name }) => setDescription(name)}
                />

                <Button
                    onClick={handleSave}
                    loading={loadingSave}
                    variant='filled'
                >Enviar Solicitação</Button>
            </Group>
        </Paper>
    </>

}